<template>
    <div v-if="pesquisa.noticia == null" class="card">
      <div class="card-body py-5 text-center">
         <i class="far fa-search font-200 text-secondary opacity-25 d-block mb-5"></i>
         <p class="font-12 mb-0 text-secondary text-uppercase weight-500">Sua busca não encontrou nenhum item correspondente</p>
         <h4 class="font-18 mt-3 mb-0">Nenhuma notícia encontrada</h4>
      </div>
   </div>

   <div v-else class="row">
      <div class="col-xxl-9 col-xl-8 col-lg-7 pe-lg-0 mb-2 mb-lg-0">
         <div class="card">
            <div class="card-body">
               <div class="row">
                  <div class="col-12 mb-2">
                     <h1 class="font-24 mb-2">{{ pesquisa.noticia.titulo }}</h1>
                     <p class="font-14 mb-0 postagem-html" v-html="pesquisa.noticia.descricao"></p>
                  </div>
                  <div class="col-12 mb-2 font-13" v-if="pesquisa.noticia.data != null">
                     <span><i class="far fa-clock color-theme font-10 me-1"></i> Postado em: </span>
                     <span>{{ String(pesquisa.noticia.data.dayOfMonth).padStart(2, '0') +'/'+ String(pesquisa.noticia.data.monthValue).padStart(2, '0') +'/'+ pesquisa.noticia.data.year }}</span>
                     <span class="ms-1">{{ String(pesquisa.noticia.data.hour).toString().padStart(2, '0') +':'+ String(pesquisa.noticia.data.minute).padStart(2, '0') }}</span>
                  </div>
                  <div class="col-12 mb-3">
                     <div class="w-100 ratio ratio-21x9">
                        <img :src="pesquisa.noticia.banner == null ? '' : pesquisa.noticia.banner" alt="bannerNoticia" class="rounded" @error="imageError">
                     </div>
                  </div>
                  <div class="col-12 postagem-html" v-html="pesquisa.noticia.conteudo"></div>
                  <div class="col-12 mt-12" v-if="pesquisa.noticia.arquivoDownload">
                     <a class="btn btn-primary btn-md font-12" :href="pesquisa.noticia.arquivoDownload" role="button" target="_blank">
                        <i class="fal fa-download font-11 me-1"></i> Baixar
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-xxl-3 col-xl-4 col-lg-5 ps-lg-1">
         <div class="card">
            <div class="card-body" v-if="pesquisa.relacionados == null || pesquisa.relacionados.length == 0">
               <h1 class="font-16 mb-3"><i class="far fa-comments-alt color-theme font-13 me-1"></i> Últimas notícias</h1>
               <div class="w-100 my-5 text-center">
                  <i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                  <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma notícia encontrada</h4>
               </div>
            </div>
            <div class="card-body" v-else>
               <h1 class="font-16 mb-3"><i class="far fa-comments-alt color-theme font-13 me-1"></i> Últimas notícias</h1>
               <notificacao v-for="(notificacao, index) in pesquisa.relacionados" :key="index" :notificacao="notificacao" :index="index" :tipo="'Notícia'"
                  :class="(index + 1) == pesquisa.relacionados.length ? '' : 'border-bottom'" />
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import notificacao from '@/components/Notificacao.vue'

export default {
	name: 'Notícia',
	data: function () {
		return {
			pesquisa: {'noticia': null, 'relacionados': []}
		}
	},
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
	watch: {
		'$route.params.idTab': function () {
         this.buscarNoticia()
      }
	},
   components: {
		notificacao
	},
	methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		buscarNoticia : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'configuracoes/getNoticia',
				params: {
					id: this.$route.query.id
				}
         }).then(response => {
				this.pesquisa = response.data

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		}
	},
	mounted() {
		this.buscarNoticia()
	}
}

</script>

<style scoped>

img[alt="bannerNoticia"] {
   object-fit: cover;
   object-position: center;
}

</style>